import React from 'react'
import { PrimaryNav } from './Header'
import './index.less'
const loadText = require('src/utils').loadText
const url = loadText('url')
const subject = loadText('headerAndFooter').subject
import { lang } from '../../utils'
const menus = [
  {
    name: subject.product,
    children: [
      {
        category: subject.application,
        children:
          lang === 'en'
            ? [{ ...url.gia, isNew: true }, url.cdn, url.turbo]
            : [url.gia],
      },
      {
        category: subject.networking,
        children: lang === 'en' ? [url.sdn, url.ipt] : [url.sdn, url.ipt],
      },
      {
        category: subject.compute,
        children:
          lang === 'en'
            ? [
                {
                  name: 'Virtual Machines',
                  link: '/products/virtualMachine',
                  icon: '/header/vm.svg',
                  description: 'Scalable virtual servers',
                },
                url.bmc,
                url.edcs,
              ]
            : [url.bmc, url.edcs],
      },
    ],
  },
  {
    name: subject.solution,
    // link: "/contact-us",
    className: 'sm-pic',
    children: [
      {
        category: subject.usecase,
        children:
          lang === 'en'
            ? [url.hybridCloud]
            : [url.hybridCloud, url.virtualEdge],
      },
      {
        category: subject.industry,
        children:
          lang === 'en'
            ? [
                url.gaming,
                url.media,
                url.cloudServiceProvider,
                url.blockchain,
                {
                  name: 'VPN',
                  link: '/solutions/vpn/',
                  icon: '/header/vpn.svg',
                },
              ]
            : [url.gaming, url.media, url.cloudServiceProvider],
      },
      {
        category: subject.emergingMarket,
        children: lang === 'zh' ? [url.sea] : [url.china, url.sea],
      },
    ],
  },
  {
    name: subject.partner,
    // link: "partner",
    layout: 'link',
    children:
      lang === 'zh'
        ? [
            url.solutionsPartner,
            url.masterAgentPartner,
            url.channelPartner,
            url.awsApn,
          ]
        : [url.solutionsPartner, url.masterAgentPartner, url.channelPartner],
  },
  {
    name: subject.company,
    layout: 'link',
    children:
      lang === 'zh'
        ? [
            url.aboutUs,
            //url.profiles,
            url.join,
            url.contact,
            url.legal,
            url.ethics,
          ]
        : [
            url.aboutUs,
            // url.profiles,
            url.join,
            url.contact,
            url.legal,
            url.ethics,
          ],
  },
  {
    name: subject.resource,
    layout: 'link',
    children:
      lang === 'zh'
        ? [
            url.downloads,
            url.customerStories,
            url.press,
            url.event,
            // url.blog,
            // url.insider,
            // url.support,
          ]
        : [
            url.whatsNew,
            url.documentation,
            url.blog,
            url.press,
            url.event,
            url.customerStories,
            url.insider,
            // url.support,
          ],
  },
]

export default function Index({
  theme,
  logo,
}: {
  theme?: string
  logo?: string
}) {
  return <PrimaryNav logo={logo || '/logo2x.png'} theme={theme} menus={menus} />
}
