import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import './index.less'
import { isMobile } from '../../utils'
//临时解决方案
import { lang } from '../../utils'
const loadText = require('src/utils').loadText
const subject = loadText('headerAndFooter').subject
const isNotIn = process.env.GATSBY_DOMAIN !== 'in'
// const isMobile = true
import newProductMap from '../../utils/newProductsMap'

const Link = ({ to, style, className, children }: any) => (
  <span
    onClick={() => {
      if (to.startsWith('http')) {
        window.open(to)
      } else {
        navigate(to)
      }
    }}
    style={{ ...style, cursor: 'pointer' }}
    className={className}
  >
    {children}
  </span>
)
import { Button } from '../ui'

// const loadText = require("src/utils").loadText
// const { subject } = loadText("headerAndFooter")

// const isDomCollide = (left, right, offset = 0) => {
//   return (
//     left.current.offsetLeft +
//       left.current.offsetWidth +
//       offset -
//       right.current.offsetLeft >
//     0
//   )
// }
// const useDomCollide = (left, right, callback, offset = 0) => {
//   const collided = useRef(false)
//   useEffect(() => {
//     console.log(left.current, right.current)
//     const check = function () {
//       if (isDomCollide(left, right, offset) && collided.current === false) {
//         callback(true, left, right)
//         collided.current = true
//       }
//       if (!isDomCollide(left, right, offset) && collided.current === true) {
//         callback(false, left, right)
//         collided.current = false
//       }
//     }
//     check()
//     window.addEventListener("resize", check)
//     return () => {
//       window.removeEventListener("resize", check)
//     }
//   }, [])
// }
type Menu = {
  name: string
  link?: string
  layout?: 'category' | 'link' | string
  icon?: string
  description?: string
  forCountry?: boolean
  className?: string
  isNew?: boolean
  children?: ({
    category?: string
  } & Partial<Menu>)[]
}

type MenuProps = {
  menus: Menu[]
  logo?: string
  theme?: string
  enableFixed?: boolean
}
export function TopNav({ menus, theme }: MenuProps) {
  return (
    <div className={`topNav ${theme}`}>
      <div className="topNav-content">
        {menus.map(({ name, link }) => (
          <div key={name} className="topNav-item">
            <Link to={`/${link}`}>{name}</Link>
          </div>
        ))}
      </div>
    </div>
  )
}
const jumpToSignin = () => {
  window.open(
    `https://console.zenlayer.com/auth?referral=${encodeURIComponent(
      `${location.origin}/${location.pathname}`
    )}`
  )
}
export const ProductNav = ({
  menus,
  name,
  minWidth,
  link,
}: {
  menus: Menu[]
  name: string
  minWidth: number
  link?: string
}) => {
  const { pathname, search, hash, state } = useLocation()
  const [active, setActive] = useState<string>(hash.slice(1))
  const nav = useRef<any>()
  const nameDom = useRef<any>()
  const itemDom = useRef<any>()
  const getAnchors = () => {
    const navHeight = nav.current.offsetHeight
    return menus.reduce((acc, cur, idx) => {
      if (window.document.getElementById(cur.link)) {
        try {
          acc.push({
            id: cur.link,
            start:
              window.document.getElementById(cur.link).offsetTop - navHeight,
            end:
              idx === menus.length - 1
                ? window.document.body.scrollHeight
                : window.document.getElementById(menus[idx + 1].link)
                    .offsetTop - navHeight,
          })
        } catch (e) {}
      }
      return acc
    }, [])
  }
  const changeHash = (h: string) => {
    setActive(h)
    window.history.replaceState(
      state,
      '',
      pathname + search + (h ? `#${h}` : '')
    )
  }
  const navigate = (id: string) => {
    const anchor = window.document.getElementById(id)
    if (anchor) {
      changeHash(id)
      window.requestAnimationFrame(() => {
        window.scrollTo({
          // top: anchor.offsetTop - nav.current.offsetHeight,
          top: anchor.offsetTop - 72,
        })
      })
    }
  }
  // useDomCollide(
  //   nameDom,
  //   itemDom,
  //   (flag, leftDom, rightDom) => {
  //     if (flag) {
  //       leftDom.current.classList.add("collapsed")
  //       rightDom.current.classList.add("collapsed")
  //     } else {
  //       leftDom.current.classList.remove("collapsed")
  //       rightDom.current.classList.remove("collapsed")
  //     }
  //   },
  //   30
  // )
  useEffect(() => {
    if (!isMobile) {
      const offset = nav.current.offsetTop // 导航栏距离页面顶部距离
      const onscroll = () => {
        const scrollY = window.pageYOffset // 垂直滚动距离
        const screenH = document.body.offsetHeight // 视口高度
        const pageH = document.body.scrollHeight // 页面高度
        const anchors = getAnchors()
        const curAnchor =
          scrollY + screenH === pageH
            ? anchors[anchors.length - 1]
            : anchors.find((a) => {
                return a.start <= scrollY && scrollY < a.end
              })
        // 更新当前锚点
        changeHash(curAnchor ? curAnchor.id : '')
        // 导航栏是否吸附顶部
        if (scrollY > offset) {
          nav.current.classList.add('sticky')
        } else {
          nav.current.classList.remove('sticky')
        }
      }
      const onresize = () => {
        if (nav.current.offsetWidth < minWidth) {
          nav.current.classList.add('collapsed')
        } else {
          nav.current.classList.remove('collapsed')
        }
      }
      // onscroll()
      onresize()
      if (active) {
        navigate(active)
      }
      window.onscroll = onscroll
      window.onresize = onresize
      return () => {
        window.onscroll = undefined
        window.onresize = undefined
      }
    }
  }, [])
  return isMobile ? null : (
    <div className="productNav-wrapper">
      <div className="productNav clearfix" ref={nav}>
        <div className="productNav-name font-20" ref={nameDom}>
          {name}
        </div>
        <div className="productNav-itemWrap font-16" ref={itemDom}>
          {menus.map(({ name, link }) => (
            <a
              key={name}
              className={`productNav-item ${active === link ? 'active' : ''}`}
              onClick={() => {
                navigate(link)
              }}
            >
              {name}
            </a>
          ))}
          {/* <Button>{subject.tryForFree}</Button> */}
        </div>
        {isNotIn && (
          <div className="product-header-btn-group">
            <Button
              size="xs"
              // outline={true}
              style={{ marginRight: '16px' }}
              onClick={() => {
                if (link) {
                  window.open(link)
                } else {
                  jumpToSignin()
                }
              }}
            >
              {subject.signIn}
            </Button>
          </div>
        )}
        {/* <div></div> */}
      </div>
      <div></div>
    </div>
  )
}

export function PrimaryNav({
  logo,
  menus,
  theme = '',
  enableFixed = true,
}: MenuProps) {
  const nameDom = useRef<any>()
  const itemDom = useRef<any>()
  const nav = useRef<any>()

  const [open, setOpen] = useState<boolean>(false)
  const [openCategory, setOpenCategory] = useState<string>('')
  useEffect(() => {
    if (enableFixed) {
      const offset = nav.current.offsetTop // 导航栏距离页面顶部距离
      const onscroll = () => {
        const scrollY = window.pageYOffset // 垂直滚动距离

        if (scrollY > offset) {
          nav.current.classList.add('sticky')
        } else {
          nav.current.classList.remove('sticky')
        }
      }
      window.onscroll = onscroll
      return () => {
        window.onscroll = undefined
      }
    }
  }, [])

  return (
    <div className={`primaryNav ${theme}`} ref={nav}>
      <Link to="/">
        {logo && (
          <img
            src={logo}
            className="primaryNav-logo"
            ref={nameDom}
            alt="zenlayer-header-logo"
          />
        )}
        {logo && (
          <img
            src={'/logo-blue.svg'}
            className="primaryNav-logo white"
            // ref={nameDom}
            alt="zenlayer-header-logo"
          />
        )}
      </Link>
      <div className="primaryNav-content" ref={itemDom}>
        {menus
          .slice(0)
          .map(({ name, children, layout = 'category', className }) => (
            <div
              className={`primaryNav-item primaryNav-item--${layout} semi-bold ${className}`}
            >
              <div className="primaryNav-link">
                <a>{name}</a>
              </div>
              {children && (
                <div className="primaryNav-dropdown">
                  {children.map(({ category, name, link, children = [] }) => {
                    if (layout === 'category') {
                      return (
                        <div
                          key={category}
                          className="primaryNav-dropdown-category"
                        >
                          <div
                            className="font-16"
                            style={{ color: '#616E82', marginBottom: '12px' }}
                          >
                            {category}
                          </div>
                          {children.map((it) => (
                            <Link key={it.name} to={it.link}>
                              {it.forCountry ? (
                                <div className="primaryNav-navItem-1 bigger">
                                  <div className="icon-name">
                                    <img src={it.icon} alt={it.name} />
                                    <p className="font-20">{it.name}</p>
                                  </div>
                                  <p className="desc">{it.description}</p>
                                </div>
                              ) : (
                                <div className="primaryNav-navItem-1">
                                  <img
                                    src={it.icon}
                                    className={`icon ${
                                      !it.description ? 'small' : ''
                                    }`}
                                    alt={it.name}
                                  />
                                  <div className="name-desc">
                                    <p className="font-16">
                                      <span
                                        style={{
                                          // position: "relative",
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: 'fit-content',
                                            alignSelf: 'flex-start',
                                          }}
                                        >
                                          {it.name}
                                        </span>
                                        {it.isNew && (
                                          <img
                                            src="/new-badge-2.svg"
                                            className="new-badge2"
                                            alt="new-product"
                                          />
                                        )}
                                      </span>
                                    </p>

                                    <p className="desc font-12">
                                      {it.description}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Link>
                          ))}
                        </div>
                      )
                    }
                    return (
                      <Link
                        key={name}
                        to={link}
                        className="font-16 primaryNav-navItem-2"
                      >
                        {name}
                      </Link>
                    )
                  })}
                </div>
              )}
            </div>
          ))}

        <div className="header-btn-group">
          {lang === 'zh' && (
            <a className="header-telephone" href="tel:4008881725 ">
              4008881725
            </a>
          )}
          {isNotIn && (
            <Button
              size="xs"
              className="only-desktop"
              style={{ marginRight: '16px' }}
              onClick={() => {
                jumpToSignin()
              }}
            >
              {subject.signIn}
            </Button>
          )}
        </div>
        {/* <a className="header-telephone only-mobile" href="tel:021-80379129 ">
          021-8037 9129
        </a> */}
        <div className="menu-icon">
          <img
            src={open ? '/header/close.svg' : '/header/menu.svg'}
            onClick={() => setOpen(!open)}
            alt={'menu'}
          />
          <div
            className="primaryNav-dropdown-mini"
            style={{ maxHeight: open ? 10000 : 0 }}
          >
            {menus.map(({ name, children = [] }) => (
              <div key={name} className="primaryNav-dropdown-mini-item">
                <div
                  className="primaryNav-dropdown-mini--title"
                  onClick={() =>
                    setOpenCategory(openCategory.includes(name) ? '' : name)
                  }
                >
                  <span className="font-24">{name}</span>
                  <img
                    alt={'submenu'}
                    src={
                      openCategory.includes(name)
                        ? '/header/minus.svg'
                        : '/header/plus.svg'
                    }
                  />
                </div>
                <div
                  className="primaryNav-dropdown-mini--category"
                  style={{
                    maxHeight: openCategory.includes(name) ? 10000 : 0,
                  }}
                >
                  {children.map((item) => {
                    if (item.category) {
                      return (
                        <div key={item.category}>
                          <div className="category-name font-12">
                            {item.category}
                          </div>
                          <div className="category-content">
                            {item.children &&
                              item.children.map((it) => (
                                <Link
                                  key={it.name}
                                  to={it.link}
                                  className="link font-28"
                                >
                                  {it.icon && (
                                    <img
                                      src={it.icon}
                                      alt={it.name}
                                      className={`${
                                        !it.description ? 'small' : ''
                                      }`}
                                    />
                                  )}
                                  <span>
                                    <p
                                      className={`link-name ${
                                        !it.description ? 'small' : ''
                                      }`}
                                    >
                                      {it.name}
                                      {it.isNew && (
                                        <img
                                          src="/new-badge-2.svg"
                                          // className="new-badge2"
                                          style={{ marginLeft: '8px' }}
                                          alt="new-product"
                                        />
                                      )}
                                    </p>
                                    {it.description && (
                                      <p className="link-desc font-20">
                                        {it.description}
                                      </p>
                                    )}
                                  </span>
                                </Link>
                              ))}
                          </div>
                        </div>
                      )
                    }
                    return (
                      <Link
                        key={item.name}
                        to={item.link}
                        className="link font-28"
                        style={{
                          display: openCategory.includes(name)
                            ? 'flex'
                            : 'none',
                        }}
                      >
                        {item.name}
                      </Link>
                    )
                  })}
                </div>
              </div>
            ))}
            <div className="header-btn-rowWrapper">
              <div className="header-btn-row">
                {isNotIn && (
                  <Button
                    size="l"
                    width={544}
                    onClick={() => {
                      jumpToSignin()
                    }}
                  >
                    {subject.signIn}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="free-btn">{subject.tryForFree}</div> */}
        {/* <img src={`/search-icon${theme ? "-" + theme : ""}.svg`} alt="" /> */}
      </div>
    </div>
  )
}
